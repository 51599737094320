import React, { useState, useRef, useEffect } from "react";
import { ChevronLeft, Share2 } from "lucide-react";
import html2canvas from "html2canvas";
import Table from "../../components/Table/Table";
import {
  formatDisplayValue,
  formatDateTime,
  formatAmount,
} from "../../utilities/commonFunction";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "../../services";
import Button from "../../components/Button/Button";
import SettlementSection from "./SettlementSection";
import Loader from "../../components/Loader/Loader";

const GameSettlementDetails = () => {
  const navigate = useNavigate();
  const { gameId } = useParams();
  const [gameData, setGameData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSharing, setIsSharing] = useState(false);
  const contentRef = useRef(null);
  const shareButtonRef = useRef(null);

  useEffect(() => {
    const fetchGameDetails = async () => {
      try {
        setLoading(true);
        const response = await API.getGameSettlementDetailsFromGameId(
          gameId,
          localStorage.getItem("token")
        );
        if (response.data?.success) {
          setGameData(response.data.data);
        }
        setError(null);
      } catch (err) {
        setError(
          err.response?.data?.error ||
            "Failed to fetch game details. Please try again later."
        );
      } finally {
        setLoading(false);
      }
    };

    if (gameId) {
      fetchGameDetails();
    }
  }, [gameId]);

  const columns = [
    {
      key: "username",
      header: "PLAYER",
    },
    {
      key: "buyIn",
      header: "BUY IN",
      render: (row) => (
        <span
          className="font-semibold text-lg"
          style={{ fontFamily: '"Bricolage Grotesque", sans-serif' }}
        >
          {formatDisplayValue(row.buyIn)}
        </span>
      ),
    },
    {
      key: "payOut",
      header: "PAYOUT",
      render: (row) => (
        <span
          className={`font-semibold text-lg ${
            parseInt(row.payOut) >= 0 ? "text-gray-900" : "text-red-500"
          }`}
          style={{ fontFamily: '"Bricolage Grotesque", sans-serif' }}
        >
          {formatDisplayValue(row.payOut)}
        </span>
      ),
    },
  ];

  const captureAndShare = async () => {
    if (!contentRef.current || isSharing) return;

    try {
      setIsSharing(true);

      // Get the container element
      const element = contentRef.current;

      // Calculate the actual visible dimensions
      const rect = element.getBoundingClientRect();
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const absoluteTop = rect.top + scrollTop;

      // Capture the content with specific dimensions
      const canvas = await html2canvas(element, {
        scale: 2,
        useCORS: true,
        backgroundColor: "#ffffff",
        windowWidth: element.scrollWidth,
        windowHeight: element.scrollHeight,
        x: rect.left,
        y: absoluteTop,
        width: rect.width,
        height: rect.height,
        logging: false,
        onclone: (clonedDoc) => {
          const clonedElement = clonedDoc.querySelector(
            '[data-html2canvas-clone="true"]'
          );
          if (clonedElement) {
            clonedElement.style.transform = "none";
            clonedElement.style.overflow = "visible";
            const fixedElements =
              clonedElement.querySelectorAll(".sticky, .fixed");
            fixedElements.forEach((el) => (el.style.position = "absolute"));
          }
        },
      });

      // Convert canvas to blob
      const blob = await new Promise((resolve) =>
        canvas.toBlob(resolve, "image/png", 1.0)
      );

      // Share or download the image
      if (navigator.share && navigator.canShare) {
        const file = new File([blob], "poker-game.png", { type: "image/png" });
        const shareData = {
          files: [file],
        };

        if (navigator.canShare(shareData)) {
          await navigator.share(shareData);
        }
      } else {
        // Fallback to direct download
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "poker-game.png";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error("Error sharing:", error);
    } finally {
      setIsSharing(false);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  if (loading) {
    return <Loader context="payout" />;
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen text-red-500">
        {error}
      </div>
    );
  }

  if (!gameData) {
    return null;
  }

  return (
    <div
      ref={contentRef}
      className="max-w-3xl mx-auto p-4 pt-0 flex flex-col gap-2 h-[calc(100%-5rem)] overflow-auto"
    >
      <header className="flex items-center mb-4 pt-4 sticky top-0 z-20 bg-white">
        <button onClick={handleBack} className="py-2">
          <ChevronLeft size={24} />
        </button>
        <h1 className="text-md font-bold w-full text-center">Settlement</h1>
      </header>

      <div className="mb-6">
        <div className="flex justify-between items-center gap-4">
          <h2
            className="text-[22px] font-semibold leading-[130%] tracking-[-0.64px] text-[#1A1C1E]"
            style={{ fontFamily: '"Bricolage Grotesque", sans-serif' }}
          >
            {gameData?.gameDetails?.gameTitle}
          </h2>

          <button
            ref={shareButtonRef}
            className="touch-manipulation p-2 active:opacity-70 relative"
            onClick={captureAndShare}
            style={{ touchAction: "manipulation" }}
            disabled={isSharing}
          >
            {isSharing ? (
              <div className="animate-spin">
                <div className="w-6 h-6 rounded-full border-2 border-gray-300 border-t-gray-600" />
              </div>
            ) : (
              <Share2 size={24} strokeWidth={2.3} />
            )}
          </button>
        </div>
      </div>

      <div className="grid grid-cols-7 gap-2 mb-6">
        <div className="col-span-2">
          <p className="text-[10px] text-gray-500">START TIME</p>
          <p
            className="font-semibold text-md"
            style={{ fontFamily: '"Bricolage Grotesque", sans-serif' }}
          >
            {formatDateTime(gameData?.gameDetails?.startTime)}
          </p>
        </div>
        <div className="col-span-1">
          <p className="text-[10px] text-gray-500">PLAYER</p>
          <p
            className="font-semibold text-lg"
            style={{ fontFamily: '"Bricolage Grotesque", sans-serif' }}
          >
            {gameData?.gameDetails?.playerCount}
          </p>
        </div>
        <div className="col-span-2">
          <p className="text-[10px] text-gray-500">AVG BUY-IN</p>
          <p
            className="font-semibold text-lg"
            style={{ fontFamily: '"Bricolage Grotesque", sans-serif' }}
          >
            {gameData?.gameDetails?.currencySymbol}
            {gameData?.gameDetails?.avgBuyIn}
          </p>
        </div>
        <div className="col-span-2">
          <p className="text-[10px] text-gray-500">TOTAL BUY-IN</p>
          <p
            className="font-semibold text-lg"
            style={{ fontFamily: '"Bricolage Grotesque", sans-serif' }}
          >
            {gameData?.gameDetails?.currencySymbol}
            {gameData?.gameDetails?.totalBuyIn}
          </p>
        </div>
      </div>

      <div className="mb-4">
        <Table
          columns={columns}
          data={gameData.players}
          customStyleClasses="max-h-[100%] flex-grow"
        />
      </div>

      {gameData?.gameDetails?.mismatch_amount !== 0 && (
        <p className="text-red-500 text-sm">
          Mismatch of{" "}
          <b>
            {gameData?.gameDetails?.mismatch_amount < 0 && "-"}{" "}
            {gameData?.gameDetails?.currencySymbol}
            {formatAmount(Math.abs(gameData?.gameDetails?.mismatch_amount))}
          </b>{" "}
          recorded.
        </p>
      )}

      <SettlementSection settlements={gameData.settlements} />
    </div>
  );
};

export default GameSettlementDetails;
